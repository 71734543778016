<template>
  <div id="detail" style="text-align: left;padding:20px;max-width:800px;margin: 0 auto;">
    <h1>{{ title }}</h1>
    <img :src="image">
    <div  v-html="content"></div>
  </div>
</template>

<script>
import $API from './api/api'
export default {
  name: 'Detail',
  components: {

  },
  data() {
    return {
      title: '',
      content: '',
      image: ''
    }
  },
  created() {
    this.loadData();
  },
  methods: {
    async loadData() {
      var url = window.location.href;
      var temp1 = url.split("?");
      var parm = temp1[1];
      var keyValue = parm.split("&");
      var obj = {};
      for (var i = 0; i < keyValue.length; i++) {
        var item = keyValue[i].split("=");
        var key = item[0];
        var value = item[1];
        obj[key] = value;
      }
      const result = await $API.articleDetail(obj.id);
      this.title = result.data.article.title;
      this.content = result.data.article.content;
      this.image = result.data.article.picUrl;
    },
  }
}
</script>

<style>
img {
  max-width: 800px;
}
</style>
